import { Box, Typography, IconButton, Container } from "@mui/material";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { styled } from "@mui/system";
import "./FooterCompagny.css";
import { useSelectCompagnyContext } from "@/context/CompagnyContext";
import ModalScanner from "../ModalScanner/ModalScanner";
import useUser from "@/hooks/useUser";
import { usePathname } from "next/navigation";
import CrayonDrawerForm from "../CrayonDrawerForm/CrayonDrawerForm";
import { useMemo } from "react";

const TikTokIcon = () => {
  return (
    <svg
      fill={"white"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="20"
      height="20"
      stroke="white"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const FooterCompagnyContainer = styled(Box)(({ theme }) => ({
  padding: "2rem",
  position: "relative",
  zIndex: 1,
}));

const SocialContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1.5rem",
}));

const FooterCompagny = () => {
  const { compagny, getSectionUi } = useSelectCompagnyContext();
  const pathname = usePathname();
  const { userId, isConected } = useUser();

  const textColor = useMemo(() => {
    return getSectionUi("footer")?.textColor || "white";
  }, [getSectionUi, compagny]);

  if (!compagny.id) return null;
  return (
    <FooterCompagnyContainer
      sx={{
        background: getSectionUi("footer")?.bgColor || compagny.primaryColor,
        paddingBottom: pathname.includes("/billets") ? "7rem" : "2rem",
      }}
    >
      <CrayonDrawerForm
        section="footer"
        title="Changer le pied de page"
        iconPosition={{ left: 0 }}
      />
      <Container maxWidth="lg">
        <SocialContainer>
          {compagny.facebookUrl && (
            <IconButton
              component="a"
              color="inherit"
              href={compagny.facebookUrl}
              target="_blank"
              sx={{
                background: "#1877F2", // Couleur officielle de Facebook
              }}
              className="link-social"
            >
              <Facebook sx={{ color: "white" }} />
            </IconButton>
          )}
          {compagny.instagramUrl && (
            <IconButton
              component="a"
              href={compagny.instagramUrl}
              target="_blank"
              sx={{
                background:
                  "radial-gradient(circle, #F58529, #DD2A7B, #8134AF)", // Dégradé Instagram
              }}
              className="link-social"
            >
              <Instagram sx={{ color: "white" }} />
            </IconButton>
          )}
          {compagny.tiktokUrl && (
            <IconButton
              component="a"
              href={compagny.tiktokUrl}
              target="_blank"
              sx={{
                background: "black", // Couleur officielle de TikTok
              }}
              className="link-social"
            >
              <TikTokIcon /> {/* Accent TikTok */}
            </IconButton>
          )}
          {compagny.youtubeUrl && (
            <IconButton
              component="a"
              href={compagny.youtubeUrl}
              target="_blank"
              sx={{
                background: "#FF0000", // Couleur officielle de YouTube
              }}
              className="link-social"
            >
              <YouTube sx={{ color: "white" }} />
            </IconButton>
          )}
        </SocialContainer>

        {/* Copyright */}
        <Box textAlign="center" mt={4}>
          <Typography
            variant="body2"
            style={{ color: textColor }}
            color="inherit"
          >
            © {new Date().getFullYear()} {compagny.name}. Tous droits réservés.
          </Typography>
        </Box>
      </Container>
      {isConected && compagny.ownerId === userId && (
        <ModalScanner primaryColor={compagny.primaryColor} />
      )}
    </FooterCompagnyContainer>
  );
};

export default FooterCompagny;
